import {Component, PLATFORM_ID, Inject} from '@angular/core';
// import { isPlatformBrowser } from '@angular/common';
import {LoadingBarService} from '@ngx-loading-bar/core';
import {Observable} from 'rxjs';
import {map, delay, withLatestFrom} from 'rxjs/operators';

// import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {

    // For Progressbar
    private loaders: Observable<any>;


    constructor(@Inject(PLATFORM_ID) private platformId: Object,
                private loader: LoadingBarService) {
        this.loaders = this.loader.value$.pipe(
            delay(1000),
            withLatestFrom(this.loader.value$),
            map(v => v[1]),
        );
    }


}
