<img src="./assets/bootstrap-icons-1.11.3/{{icon}}.svg" alt="Logo" *ngIf="getSvgType(); else fill"/>

<ng-template #fill>
    <img src="./assets/bootstrap-icons-1.11.3/{{icon}}-fill.svg" alt="Logo"/>
</ng-template>

<!--
<svg class="stroke-icon" *ngIf="getSvgType(); else fill">
  <use attr.xlink:href="assets/svg/icon-sprite.svg#stroke-{{icon}}"></use>
</svg>
<ng-template  #fill>
  <svg class="fill-icon">
    <use attr.xlink:href="assets/svg/icon-sprite.svg#fill-{{icon}}"></use>
  </svg>
</ng-template>-->
